<template lang='pug'>
main-layout
  .legal-page-wrapper
    h1 Privacy Policy
    //- If updating this page/update date, update the constant TERMS_AND_POLICY_UPDATED_AT in ENV file
    p.small.italic This Privacy Policy was last updated on February 12, 2018.
    hr

    p
      | Thank you for visiting the <router-link to='/'>app.advisorey.com</router-link> website (the “Site”), operated by Aardor, Inc. DBA advisorey. (“we,” “our” or “us”) is committed to protecting the privacy of its online visitors. This Privacy Policy is designed to explain how the Site collects, uses and shares Personal Information (information that can be used to identify you). It applies to users of our Site in the United States only. By using this Site, you are agreeing to this Privacy Policy.
    p This Privacy Policy is subject to and incorporates by reference our <router-link to='/terms-of-service'>Terms of Service</router-link>.

    h3 Information You Provide To Us
    p When you use our Site, we collect the information you choose to share with us. For example, we collect Personal Information when you create an account, complete an assessment or application to obtain or offer coaching services, fill out a questionnaire or other form, request customer support or otherwise communicate with us.
    p The types of information we may collect include your first and last name, email address, mailing and billing address, payment information and any other information you choose to provide. Additionally, in order for us to match you with the best coach or student, our assessment may ask you to provide details regarding your education, work experience and skill set. Once you are paired with your coach/student, you will be able to communicate with each other confidentially, and we will have no visibility into the content of your correspondence.

    h3 Information We Collect Automatically
    p
      | When you access our Site, we may automatically collect certain information about you, including the following:
      ul
        li <i>Location Information:</i> In certain circumstances (e.g., when location detection is enabled on your mobile device), we may collect location information.
        li <i>Cookies:</i> Like many websites, we use cookies to help our Site function properly. Cookies are small text files that are deposited on your device. We use cookies to remember user log-in details, provide secure log-in and collect statistics to optimize functionality of our Site. It may be possible to disable some cookies through your device or browser setting. Please note, however, that certain features of our Site may not be available if cookies are disabled.
        li <i>Pixels or Web Beacons:</i> We use pixel tags or web beacons, which are transparent graphic images placed on a web page or in an email and indicate that a page or email has been viewed or tell your browser to get content from another server.
        li <i>Device Information:</i> We also automatically collect information about the computer or mobile device you use to access our Site, including IP addresses or other unique identifier information. (“Device Identifiers”). A Device Identifier is a number that is automatically assigned to your device when you access our website or servers, and our computers identify your device by its Device Identifier. We may use a Device Identifier, among other things, to administer the Site, help diagnose problems with our servers and gather broad demographic information.
        li <i>Additional Information:</i> We may also collect information about your use of the Site, including the type of browser you use, access times, pages viewed and the address of the website you visited before navigating to the Site.

    h3 Use of Information
    p
      | The purposes for which we may use your Personal Information include:
      ul
        li To identify users and to reply to your questions, comments and requests;
        li For internal business purposes, such as auditing, data analytics and research to create, develop, operate, deliver and improve our Site, services and customer communications;
        li To provide content or features that match user profiles or interests;
        li To communicate with you about our new products, services, offers and events;
        li To process payments authorized by you; and
        li To protect and defend our rights and property, including by way of legal proceedings, and to comply with regulatory requirements.

    h3 Sharing of Information
    p
      | We may share your Personal Information in a limited number of circumstances, including:
      ul
        li <i>With Our Third-Party Service Providers:</i> We share Personal Information with third parties involved in the process of providing services to us or you or performing functions on our behalf (including payment processing). Those third parties are only permitted to use your Personal Information for the purpose that it has been provided and may not disclose it to any other third party except at our express direction and in accordance with this Privacy Policy.
        li <i>To Protect Our Interests & Comply with the Law:</i> It may be necessary—due to applicable law, legal process or litigation—for us to disclose your Personal Information, e.g., to government or law enforcement authorities.
        li <i>Business Transition:</i> In the event of a proposed or actual purchase, any reorganization, sale, lease, merger, joint venture, assignment, amalgamation or any other type of acquisition, disposal or financing of all or any portion of our business or of any of the business assets or shares (including in connection with any bankruptcy or similar proceeding).
        li <i>Other Parties Where We Believe Necessary or Appropriate:</i> We may also disclose your information (i) where we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation or prosecution of suspected or actual illegal activity; or (ii) in the good faith believe that disclosure is otherwise necessary or advisable.
    p We also may use and disclose anonymized information in any manner permitted by applicable law.

    h3 Children
    p Due to the nature of Advisorey’s business, our Site is not directed to minors. advisorey. does not knowingly solicit, collect, use or disclose Personal Information from children under 13 years of age. If we become aware that we have unknowingly collected Personal Information from a child under the age of 13, we will delete such information from our records.

    h3 California Do-Not-Track Disclosure
    p Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. DNT is a way for users to inform websites and services that they do not want certain information about their webpage visits collected over time and across websites or online services. advisorey. does not currently recognize or respond to browser-initiated DNT signals.

    h3 California Privacy Rights
    p California law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the third parties to whom we have disclosed their Personal Information (if any) for their direct marketing purposes in the prior calendar year, as well as the type of Personal Information disclosed to those parties. advisorey. does not share Personal Information with third parties for their own marketing purposes.

    h3 Protection of Personal Information
    p advisorey. takes the security of Personal Information provided to us very seriously, and we endeavor to take reasonable steps designed to protect your Personal Information. Unfortunately, no data transmission over the Internet or storage of information can be guaranteed to be 100% secure. As a result, while advisorey. strives to protect your Personal Information, we cannot ensure or warrant the security of any information you transmit to advisorey., and you do so at your own risk.

    h3 Cross-Border Transfers
    p Your Personal Information may be transferred across national borders and may be accessed by advisorey. vendors and suppliers from countries with varying degrees of protection for your Personal Information. By agreeing to this Privacy Policy, you expressly consent to the processing of your data in any jurisdiction, including, without limitation, the United States of America, in accordance with this Privacy Policy and applicable privacy and data protection laws and regulations.

    h3 External Links
    p Our Site may contain links to third-party sites that are not owned or operated by advisorey.. This Privacy Policy only applies to advisorey. and does not apply to any third-party sites. advisorey. is not responsible or liable for the privacy practices or content of such other websites, even if our name or logo appears on the screen. We recommend that you review the privacy policies on third-party sites before you use any services or programs they offer.

    h3 Revision of this Privacy Policy
    p We may update this Privacy Policy from time to time without advance notice. Accordingly, we encourage you to periodically check for updates to this Privacy Policy. We will identify the date the Privacy Policy was last updated at the beginning of the policy. We will use your Personal Information in a manner consistent with the Privacy Policy in effect at the time you submitted the information, unless you consent to the new or revised policy. If we make any significant changes in the way we use your Personal Information, we will alert you by posting a prominent notice when you open our App or visit our Site.

    h3 Contact Information
    p If you have questions or comments about our Privacy Policy, please email us at <a href='mailto: info@advisorey.com' target='_blank'>info@advisorey.com</a>.




</template>

<script>
import { makeSeoRefs } from '@/utils/meta';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
};

export default {
  components,
  metaInfo: {
    title: 'Privacy Policy',
    link: makeSeoRefs('privacy-policy'),
  },
};
</script>

<style lang='less'>
// See terms-of-service.vue for styling
</style>
