<template lang='pug'>
main-layout#page-signup
  template(v-if='signupRequest.isSuccess')
    h2 Thank You!
    p <b>Thanks for signing up. Someone on the advisorey. team will contact you shortly.</b>
    p
      | We find that it's best to go over our platform and how it works with a real person, so you can get answers to any questions you may have.
      | We look forward to having your company on board!
  template(v-else-if='getEnumsRequest.isPending')
    h2 loading...
  template(v-else)

    h1 Let us help your executives grow
    p

    p
      icon(name='exclamation-circle')
      |  Are you an executive coach looking to find clients on our platform?
      =' '
      router-link(:to='{name: "coach-signup"}') Sign up here instead!

    form-row
      form-input(
        v-model='company.name'
        label='What is the name of your company?'
        required
      )
      form-input(v-model='company.websiteUrl' type='url' label='Company Website')
    form-row
      form-input(
        v-model='company.country' type='dropdown'
        label='In what country is your company based?'
        :options='countryOptions'
        auto-select
        required
      )
    form-row
      form-input(
        v-model='company.size' type='dropdown'
        label='Company size'
        :options='companySizeOptions'
        required
      )
      form-input(
        v-model='company.industry' type='dropdown'
        label='Industry'
        :options='industryOptions'
        required
      )
      form-input(
        v-if='company.industry === "other"'
        v-model='company.industryOther'
        label='Other industry?'
        required
      )

    form-group(label='About you')
      form-row
        form-input(v-model='companyAdmin.firstName' label='Your name' placeholder='First name' required)
        form-input(v-model='companyAdmin.lastName' placeholder='Last name' required)
      form-row
        form-input(v-model='companyAdmin.email' type='email' label='Email' required)
        form-input(v-model='companyAdmin.phone' type='phone' label='Phone' required)
        form-input(
          v-model='companyAdmin.bestContactTime' type='dropdown'
          label='Best Time To Contact'
          :options='contactTimeOptions'
        )
      form-row
        form-input(v-model='companyAdmin.jobTitle' label='Your Job Title')


    form-row(inputs-wide-on-small)
      form-input(
        type='dropdown'
        v-model='companyAdmin.selfReportedSource'
        label='How did you hear about us?'
        placeholder='- select -'
        :options='SELF_REPORTED_SOURCE_OPTIONS'
      )

      form-input(
        v-model='companyAdmin.selfReportedSourceDetail'
        v-if='!!selfReportedSourceDetailLabel'
        :label='selfReportedSourceDetailLabel'
      )

    form-row
      v-button(
        @click='applyButtonHandler'
        :disabled='$vv.$error'
        :request-status='signupRequest' loading-text='Submitting your info...'
      ) Sign Up
      error-message(:request-status='signupRequest')


</template>

<script>
import { makeSeoRefs } from '@/utils/meta';

import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import { SELF_REPORTED_SOURCE_OPTIONS, SELF_REPORTED_SOURCE_DETAIL_PROMPTS } from '@/data/self-reported-source';
import { BEST_CONTACT_TIMES } from '@/data/best-contact-times';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
};

export default {
  components,
  metaInfo: {
    title: 'Enroll Your Company',
    link: makeSeoRefs('company-signup'),
  },
  mixins: [vuelidateGroupMixin],
  data() {
    let detectedTimezone;
    try {
      detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {} // eslint-disable-line no-empty

    return {
      company: {
      },
      companyAdmin: {
        timezone: detectedTimezone,
      },
    };
  },
  computed: {
    ...mapGetters('enums', [
      'industryOptions', 'companySizeOptions', 'countryOptions',
    ]),
    ...mapRequestStatuses({
      signupRequest: 'auth/COMPANY_SIGNUP',
      getEnumsRequest: 'enums/GET_ENUMS',
    }),
    SELF_REPORTED_SOURCE_OPTIONS() { return SELF_REPORTED_SOURCE_OPTIONS; },
    selfReportedSourceDetailLabel() {
      return SELF_REPORTED_SOURCE_DETAIL_PROMPTS[this.companyAdmin.selfReportedSource];
    },
    contactTimeOptions: () => BEST_CONTACT_TIMES,
  },
  methods: {
    async applyButtonHandler() {
      if (this.$hasError()) return;
      await this.$store.dispatchApiAction('auth/COMPANY_SIGNUP', {
        company: this.company,
        companyAdmin: this.companyAdmin,
      });
      // if (this.signupRequest.isSuccess)
    },
  },
  beforeMount() {
    if (!this.getEnumsRequest.isSuccess) {
      this.$store.dispatchApiAction('enums/GET_ENUMS');
    }
  },
};
</script>

<style lang='less'>

</style>
